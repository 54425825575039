<template>
  <jaya-connection-layout text="Vérification de votre compte en cours...">
  </jaya-connection-layout>
</template>

<script>
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";
import {BCol, BRow} from "bootstrap-vue";

export default {
  components: {
    JayaConnectionLayout,
    BRow,
    BCol,
  },

  mounted() {
    let userInfos = this.$route.query
    this.$loading(true)
    this.$store.dispatch('account/register_confirmation', userInfos)
        .then(() => {
          this.$loading(false)
          this.$notify({
            time: 4000,
            color: "primary",
            title: "Bienvenue",
            text: "Bienvenue au jardin"
          })
          if (process.env.VUE_APP_USE_TOKEN) {
            this.$fireLoginDone();
          }
          this.$router.push({name: 'jayaccueil'})
        })
        .catch(error => {
          this.$loading(false)
          if (error === "invalidSignature") {
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Erreur",
              text: "Lien invalide"
            })
          } else {
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Erreur",
              text: "Une erreur est survenue veuillez réessayer plus tard"
            })
          }
        })
  },
}
</script>

<style lang="scss">
</style>
